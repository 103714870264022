<template>
    <div id="proxy-userfr-list">

        <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="用户ID">
                <el-input v-model="form.userId" placeholder="ID"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
                <el-input v-model="form.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item label="资金类型">
                <el-select v-model="form.fundType" placeholder="请选择" clearable>
                    <el-option v-for="fundType in Object.keys(FUND_TYPE)" :key="fundType" :label="FUND_TYPE[fundType]"
                        :value="fundType">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="收支类型">
                <el-select v-model="form.fundMode" placeholder="请选择" clearable>
                    <el-option v-for="fundMode in Object.keys(FUND_MODE)" :key="fundMode" :label="FUND_MODE[fundMode]"
                        :value="fundMode">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker v-model="ctRange" type="datetimerange" :picker-options="pickerOptions"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                    format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <!-- -
            <el-form-item>
                <el-date-picker v-model="form.ctEnd" type="datetime" placeholder="结束时间" format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="page.list" border>
            <el-table-column prop="id" label="ID">
            </el-table-column>
            <el-table-column prop="createTime" label="时间">
            </el-table-column>
            <el-table-column label="用户">
                <template v-slot="row">
                    [{{ row.row.userId }}] {{ row.row.email }}
                </template>
            </el-table-column>
            <el-table-column label="资金类型">
                <template v-slot="row">
                    {{ FUND_TYPE[row.row.fundType] }}
                </template>
            </el-table-column>
            <el-table-column label="金额">
                <template v-slot="row">
                    <span :class="row.row.fundMode + '_span'"> {{ FUND_MODE[row.row.fundMode] }} </span>
                    <span class="amount_span"> {{
                        row.row.amount }}</span>
                </template>
            </el-table-column>
            <el-table-column label="明细">
                <template v-slot="row">
                    <el-descriptions class="margin-top" column="1" border>
                        <el-descriptions-item label="可提">
                            {{ row.row.amountDetail.freeAmount }}
                        </el-descriptions-item>
                        <el-descriptions-item label="不可提">
                            {{ row.row.amountDetail.limitAmount }}
                        </el-descriptions-item>
                    </el-descriptions>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import {list} from './UserFundRecord'
import {FUND_MODE, FUND_TYPE} from '../../util/constants'

export default {
    name: "ProxyFundRecordList",
    data() {
        return {
            FUND_MODE,
            FUND_TYPE,
            form: {
                pageNo: 1,
                pageSize: 20,
                userId: this.$route.query.userId,
                email: null,
                fundType: null,
                fundMode: null,
                ctStart: null,
                ctEnd: null,
            },

            ctRange: [],

            page: {
                list: null,
                totalPage: null,
            },
        };
    },

    methods: {

        loadList() {
            if(this.ctRange != null && this.ctRange.length > 0){
                this.form.ctStart = this.ctRange[0];
                this.form.ctEnd = this.ctRange[1];
            }
            list(this.form).then((data) => {
                this.page = data;
            });
        },

      changePage(val) {
        this.form.pageNo = val;
        this.loadList();
      },

    },

    created() {
        this.loadList();
    },

};
</script>

<style scoped>
.IN_span {
    color: #07ce75;
}

.OUT_span {
    color: #f03636;
}

.amount_span {
    color: #3e3e3d;
    font-size: medium;
}
</style>